import { AWS } from '@/cognito/auth'
const KVSWebRTC = require('amazon-kinesis-video-streams-webrtc');

import { getWebRTCRegion } from '@/websocket/drone-websocket-api'

const KINESIS_CHANNEL_NAME = process.env.VUE_APP_KINESIS_CHANNEL_NAME;

export const startKinesisStreaming = async (streaming, drone_uuid) => {
  const kinesisVideoClient = new AWS.KinesisVideo({
    region: getWebRTCRegion(),
    accessKeyId: AWS.config.credentials.accessKeyId,
    secretAccessKey: AWS.config.credentials.secretAccessKey,
    sessionToken: AWS.config.credentials.sessionToken,
  });

  const describe_signaling_channel_res = await kinesisVideoClient
    .describeSignalingChannel({
        ChannelName: KINESIS_CHANNEL_NAME + drone_uuid,
    }).promise();
  const channel_arn = describe_signaling_channel_res.ChannelInfo.ChannelARN;

  const get_signaling_channel_endpoint_res = await kinesisVideoClient
    .getSignalingChannelEndpoint({
      ChannelARN: channel_arn,
      SingleMasterChannelEndpointConfiguration: {
        Protocols: ['WSS', 'HTTPS'],
        Role: KVSWebRTC.Role.VIEWER,
      },
    }).promise();

  const endpoints_by_protocol = get_signaling_channel_endpoint_res.ResourceEndpointList.reduce((endpoints, endpoint) => {
    endpoints[endpoint.Protocol] = endpoint.ResourceEndpoint;
    return endpoints;
  }, {});

  const kinesisVideoSignalingChannelsClient = new AWS.KinesisVideoSignalingChannels({
    region: getWebRTCRegion(),
    accessKeyId: AWS.config.credentials.accessKeyId,
    secretAccessKey: AWS.config.credentials.secretAccessKey,
    sessionToken: AWS.config.credentials.sessionToken,
    endpoint: endpoints_by_protocol.HTTPS,
    correctClockSkew: true,
  });

  const get_ice_server_config_res = await kinesisVideoSignalingChannelsClient
    .getIceServerConfig({
        ChannelARN: channel_arn,
    }).promise();

  const ice_servers = [
    { urls: `stun:stun.kinesisvideo.${getWebRTCRegion()}.amazonaws.com:443` }
  ];

  get_ice_server_config_res.IceServerList.forEach(ice_server =>
    ice_servers.push({
      urls: ice_server.Uris,
      username: ice_server.Username,
      credential: ice_server.Password,
    }),
  );

  const signaling_client = new KVSWebRTC.SignalingClient({
    channelARN: channel_arn,
    channelEndpoint: endpoints_by_protocol.WSS,
    clientId: Math.random().toString(36).substring(2).toUpperCase(),
    role: KVSWebRTC.Role.VIEWER,
    region: getWebRTCRegion(),
    credentials: {
      accessKeyId: AWS.config.credentials.accessKeyId,
      secretAccessKey: AWS.config.credentials.secretAccessKey,
      sessionToken: AWS.config.credentials.sessionToken,
    },
    systemClockOffset: kinesisVideoClient.config.systemClockOffset,
  });
  streaming["signaling_client"] = signaling_client;

  const peer_connection = new RTCPeerConnection({ iceServers: ice_servers });
  streaming["peer_connection"] = peer_connection;

  peer_connection.addEventListener("icecandidate", ({ candidate }) => {
    if (candidate) {
      signaling_client.sendIceCandidate(candidate);
    } else {
      // No more ICE candidates will be generated
    }
  });

  peer_connection.addEventListener('track', event => {
      if (streaming["remote_view"].srcObject) {
          return;
      }
      streaming["remote_stream"] = event.streams[0];
      streaming["remote_view"].srcObject = streaming["remote_stream"];
  });

  peer_connection.addEventListener('iceconnectionstatechange', event => {
    if (peer_connection.iceConnectionState === 'failed') {
      stopKinesisStreaming(streaming);
    }
  });

  signaling_client.on("open", async () => {
    await peer_connection.setLocalDescription(
      await peer_connection.createOffer({
        offerToReceiveVideo: true,
        offerToReceiveAudio: true,
      }),
    );
    signaling_client.sendSdpOffer(peer_connection.localDescription);
  });

  signaling_client.on("sdpAnswer", async answer => {
    await peer_connection.setRemoteDescription(answer);
  });

  signaling_client.on('iceCandidate', candidate => {
    peer_connection.addIceCandidate(candidate);
  });

  signaling_client.on('close', () => {
    console.warn('signaling_client: close');
  });

  signaling_client.on('error', error => {
    console.error('signaling_client: error', error);
  });

  signaling_client.open();
}

export const stopKinesisStreaming = (streaming) => {
  if (streaming["signaling_client"]) {
    streaming["signaling_client"].close();
    streaming["signaling_client"] = null;
  }

  if (streaming["peer_connection"]) {
    streaming["peer_connection"].close();
    streaming["peer_connection"] = null;
  }

  if (streaming["remote_stream"]) {
    streaming["remote_stream"].getTracks().forEach(track => track.stop());
    streaming["remote_stream"] = null;
  }

  if (streaming["remote_view"]) {
    streaming["remote_view"].srcObject = null;
  }
}
