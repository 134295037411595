import { Loader } from "@googlemaps/js-api-loader"

var map = null;
var markers = [];
var infoWindows = [];
var line = null;
var polyline = [];

const mapLoader = new Loader({
  apiKey: process.env.VUE_APP_GOOGLEMAP_API_KEY
});

export const initializeMap = () => {
  resetImageMarkers();
  resetPolyline();
  if (map) {
    map = null;
  }
  mapLoader.load().then((google) => {
    map = new google.maps.Map(document.getElementById("map"), {
      center: { lat: -34.397, lng: 150.644 },
      zoom: 18
    });
  });
}

export const resetImageMarkers = () => {
  infoWindows = [];
  markers.forEach((elem, idx) => {
    elem.setMap(null);
  });
  markers = [];
}

export const resetPolyline = () => {
  if (line) {
    line.setMap(null);
    line = null;
  }
  polyline = [];
}

export const addImageMarker = (latlng, content) => {
  if (markers.length >= process.env.VUE_APP_IMAGES_PER_PAGE) {
    markers[0].setMap(null);
    markers.shift();
    infoWindows.shift();
  }

  let marker;
  marker = new google.maps.Marker({
    position: latlng,
    map: map
  });
  markers.push(marker);
  let infoWindow = new google.maps.InfoWindow({
    content: content,
  });
  marker.addListener("click", () => {
    infoWindow.open(map, marker);
  });
  infoWindows.push(infoWindow);
}

export const addPolyline = (latlng) => {
  if (polyline.length > 999) {
    polyline.shift();
  }
  polyline.push(latlng);
  if (!line) {
    line = new google.maps.Polyline({
      path: polyline,
      geodesic: true,
      strokeColor: "#E65100",
      strokeOpacity: 1.0,
      strokeWeight: 2,
      icons: [
        {
          icon: {
            path: google.maps.SymbolPath.FORWARD_OPEN_ARROW,
            scale: 2,
            strokeColor: '#E65100',
            strokeWeight: 2
          },
          offset: '100%'
        },
      ]
    });
    line.setMap(map);
  } else {
    line.setPath(polyline);
  }

  if (polyline.length == 1) {
    map.setCenter(polyline.slice(-1)[0]);
  }
}

export const updateMapCenter = () => {
  if (polyline.length > 0) {
    map.setCenter(polyline.slice(-1)[0]);
  }
}

