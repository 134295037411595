<template>
  <div class="home">
    <BootstrapSidebar
      :initial-show="initialShow"
      :links="links"
      :header="header"
      :fa="true"
      @sidebarChanged="onSidebarChanged"
    >
      <template v-slot:navbar>
        <b-navbar
          id="mainNavbar"
          toggleable="lg"
          type="light"
          variant="light"
          fixed="top"
        >
          <b-navbar-nav>
            <b-nav-item> Drone Cloud </b-nav-item>
          </b-navbar-nav>

          <b-navbar-nav class="ml-auto">
            <b-nav-item-dropdown right>
              <template #button-content>
                <em>{{ region }}</em>
              </template>
              <b-dropdown-item v-for="region in regions" href="" v-on:click.stop.prevent="selectRegion(region)">{{ region }}</b-dropdown-item>
            </b-nav-item-dropdown>
            <b-nav-item-dropdown right>
              <template #button-content>
                <em>{{ email }}</em>
              </template>
              <b-dropdown-item href="" v-on:click.stop.prevent="doSignOut">Sign Out</b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>
        </b-navbar>
      </template>

      <template v-slot:content>
        <!-- <b-container style="margin-top: 56px"> -->
        <div class="px-3 pb-5" style="margin-top: 56px">
          <router-view />
        </div>
        <!-- </b-container> -->
      </template>
    </BootstrapSidebar>
  </div>
</template>

<style lang="scss">
@import "node_modules/vue-bootstrap-sidebar/src/scss/default-theme";
</style>

<script>
import { logoutUser, getEmail } from '@/cognito/auth'
import { initializeConfig, getApiEndpoints, getCurrentRegion, changeRegion, connectDroneWebSocket, sendLogin, setGetDroneInfoCallback } from '@/websocket/drone-websocket-api'
import BootstrapSidebar from 'vue-bootstrap-sidebar'

const initializeDroneWebSocket = (th) => {
  connectDroneWebSocket();
  setGetDroneInfoCallback((links) => {
    th.links = links;
  });
  sendLogin();
}

export default {
  name: 'HomeView',
  components: {
    BootstrapSidebar
  },
  data () {
    return {
      initialShow: false,
      header: "<h3>Drones</h3>",
      links: [],
      email: getEmail(),
      region: getCurrentRegion(),
      regions: [],
    };
  },
  methods: {
    onSidebarChanged() {},
    doSignOut() {
      logoutUser().then(() => {
        this.$router.push('/login');
      });
    },
    selectRegion(region_string) {
      this.region = region_string;
      changeRegion(region_string);
      initializeDroneWebSocket(this);
      if (this.$router.currentRoute.path != "/") {
        this.$router.push('/');
      }
    },
  },
  created() {
    initializeConfig(() => {
      initializeDroneWebSocket(this);
      this.regions = Object.keys(getApiEndpoints()).sort();
    });
  }
}
</script>
