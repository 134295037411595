<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App',
  methods: {
    handleStyles () {
      if (['/login'].includes(this.$route.path)) {
        document.querySelector('html').classList.add('login-html');
        document.body.className = 'login-body';
      } else {
        document.querySelector('html').classList.remove('login-html');
        document.body.className = '';
      }
    }
  },
  mounted () {
    this.handleStyles()
  },
  watch: {
    '$route' () {
      this.handleStyles()
    }
  }
}
</script>

<style>
#app {
  width: 100%;
}

body {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

html.login-html {
  height: 100%;
}

body.login-body {
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #f5f5f5;
}
</style>
