import { getUsername, getAccessToken, getIdToken } from '@/cognito/auth'
import axios from 'axios';

const CMD_KEY = "cmd";
const CMD_TYPE = "type";
const CMD_LOGIN_KEY = "login";
const CMD_LOGIN_KEY_USER = "user";
const CMD_LOGIN_KEY_TOKEN = "token";
const AUTH_KEY = "auth";
const AUTH_KEY_USER = "user";
const AUTH_KEY_SKEY = "skey";
const ACTION_KEY = "action";
const ACTION_VAL_SYS_BROWSER = "SYS_BROWSER";
const CMD_GET_DRONE_INFO_KEY = "get_drone_info";
const CMD_RES_DRONE_INFO_KEY = "drone_info";
const CMD_GET_DRONE_INFO_REGISTRATION_DATE_KEY = "registration_date";
const CMD_GET_DRONE_INFO_MODEL_NAME_KEY = "model_name";
const CMD_GET_DRONE_STATUS_KEY = "get_drone_status";
const CMD_RES_DRONE_STATUS_KEY = "drone_status";
export const CMD_GET_DRONE_STATUS_KEY_TIMESTAMP = "timestamp";
export const CMD_GET_DRONE_STATUS_KEY_LOCATION = "location";
export const CMD_GET_DRONE_STATUS_KEY_RTK_LOCATION = "rtk_location";
export const CMD_GET_DRONE_STATUS_KEY_DIRECTION = "direction";
export const CMD_GET_DRONE_STATUS_KEY_FLIGHT_TIME = "flight_time";
export const CMD_GET_DRONE_STATUS_KEY_TEMPERATURE = "sys_temp";
export const CMD_GET_DRONE_STATUS_KEY_SYS_BATTERY = "sys_battery";
export const CMD_GET_DRONE_STATUS_KEY_COMM_LINK = "comm_link";
export const CMD_GET_DRONE_STATUS_KEY_RF_STRENGTH = "rf_strength";
export const CMD_GET_DRONE_STATUS_KEY_LATEST_FILE_UPTIME = "latest_file_uptime";
export const CMD_GET_DRONE_STATUS_KEY_DRN_STATUS = "drn_status";
export const CMD_GET_DRONE_STATUS_KEY_MISSION = "misc_stat0";
const DRONE_UUID = "drone_uuid";
const DATA_KEY = "data";
const CMD_GET_CAMERA_PROPERTY = "get_camera_property";
const CMD_RES_GET_CAMERA_PROPERTY = "camera_property";
const CMD_NOTIFY_CAMERA_PROPERTY = "notify_camera_property";
const CMD_BROWSER_CAMERA_CONTROL = "browser_camera_control";
const CAMERA_ACTION_KEY = "camera_action";
const CAMERA_PARAMS_KEY = "camera_params";
const CAMERA_ACTION_CAMERA_SET_PARAMS = "camera_set_params";
const CAMERA_ACTION_ONE_SHOT = "one_shot";
const CAMERA_ACTION_BURST_SHOT = "burst_shot";
const CAMERA_ACTION_BURST_SHOT_FLAG = "burst_shot";

let apiEndpoints = {};
var wsConn = null;
var gSKey = null;
var getDroneInfoCallback = null;
var getDroneStatusCallback = null;
var getCameraPropertyCallback = null;
var onOpenCallback = null;
var onCloseCallback = null;
var currPollId = null;
var currDroneInfo = [];
var currDroneStatus = null;
var currCameraProperty = null;
var currRegion = process.env.VUE_APP_DEFAULT_WEBSOCKET_API_REGION;

export const initializeConfig = (callback) => {
  axios.get(process.env.VUE_APP_CONFIG_REST_API).then((res) => {
    res.data.Items.forEach((elem, idx) => {
      apiEndpoints[elem.region] = elem.BrowserWebsocketApiEndpoint;
    });
  }).then(() => {
    callback();
  }).catch((err) => {
    console.error('initializeConfig: error', error);
  }).finally(() => {
  });
}

export const getCurrentRegion = () => {
  return currRegion;
}

export const getWebRTCRegion = () => {
  // NOTE: us-west-1 does not provide kinesis streaming service
  return currRegion == "us-west-1" ? "us-west-2" : currRegion;
}

export const getApiEndpoints = () => {
  return apiEndpoints;
}

export const changeRegion = (region) => {
  closeDroneWebSocket();
  currRegion = region;
}

export const setOnOpenCallback = (callback) => {
  onOpenCallback = callback;
}

export const setOnCloseCallback = (callback) => {
  onCloseCallback = callback;
}

export const connectDroneWebSocket = () => {
  var ws_url = apiEndpoints[currRegion] + "?IdToken=" + getIdToken().getJwtToken();
  wsConn = new WebSocket(ws_url);

  wsConn.onopen = (event) => {
    console.log("onOpen");
    if (onOpenCallback != null) {
      onOpenCallback();
    }
  };

  wsConn.onmessage = (msg) => {
    var rcv_msg = JSON.parse(msg["data"]);
    var cmd = rcv_msg[CMD_KEY]

    console.log("onMessage: " + cmd);

    if (cmd == CMD_LOGIN_KEY) {
      gSKey = rcv_msg[AUTH_KEY_SKEY];
      sendGetDroneInfo();
    } else if (cmd == CMD_GET_DRONE_INFO_KEY) {
      var drone_links = []
      currDroneInfo = rcv_msg[CMD_RES_DRONE_INFO_KEY]
      currDroneInfo.sort((first, second) => {
        var first_date = new Date(first[CMD_GET_DRONE_INFO_REGISTRATION_DATE_KEY])
        var second_date = new Date(second[CMD_GET_DRONE_INFO_REGISTRATION_DATE_KEY])
        return (first_date > second_date) - (first_date < second_date)
      })
      currDroneInfo.forEach((elem, idx) => {
        drone_links.push({ name: elem[CMD_GET_DRONE_INFO_MODEL_NAME_KEY] || "No model name", href: "/drone/" + elem[DRONE_UUID] });
      });
      if (getDroneInfoCallback != null) {
        getDroneInfoCallback(drone_links)
      }
    } else if (cmd == CMD_GET_DRONE_STATUS_KEY) {
      currDroneStatus = rcv_msg[CMD_RES_DRONE_STATUS_KEY]
      if (getDroneStatusCallback != null) {
        if (currDroneStatus.length != 0) {
          getDroneStatusCallback(currDroneStatus[0])
        } else {
          currDroneStatus = [{}]
          getDroneStatusCallback(currDroneStatus[0])
        }
      }
    } else if (cmd == CMD_NOTIFY_CAMERA_PROPERTY) {
      currCameraProperty = JSON.parse(rcv_msg[CMD_RES_GET_CAMERA_PROPERTY])
      if (getCameraPropertyCallback != null) {
        if (currCameraProperty.camera_property) {
          getCameraPropertyCallback(currCameraProperty.camera_property)
        }
      }
    }
  };

  wsConn.onclose = (event) => {
    console.log("onClose: code = " + event.code + ", reason = " + event.reason);
    if (onCloseCallback != null) {
      onCloseCallback();
    }
  };

  wsConn.onerror = (event) => {
    console.log("onError");
    console.log(event);
  };
}

export const getDroneWebSocket = () => {
  return wsConn;
}

export const sendLogin = () => {
  var json_sys_pilot = {};
  var json_packet = {};
  json_sys_pilot[CMD_KEY] = CMD_LOGIN_KEY;
  json_sys_pilot[CMD_TYPE] = "req";
  json_sys_pilot[CMD_LOGIN_KEY_USER] = getUsername();
  json_sys_pilot[CMD_LOGIN_KEY_TOKEN] = getAccessToken().getJwtToken();
  json_sys_pilot[ACTION_KEY] = ACTION_VAL_SYS_BROWSER;
  json_packet[DATA_KEY] = json_sys_pilot;
  sendMsg(JSON.stringify(json_packet));
}

export const sendGetDroneInfo = () => {
  var json_sys_pilot = {};
  var json_packet = {};
  json_sys_pilot[CMD_KEY] = CMD_GET_DRONE_INFO_KEY;
  json_sys_pilot[CMD_TYPE] = "req";
  json_sys_pilot[AUTH_KEY_USER] = getUsername();
  json_sys_pilot[AUTH_KEY_SKEY] = gSKey;
  json_sys_pilot[ACTION_KEY] = ACTION_VAL_SYS_BROWSER;
  json_packet[DATA_KEY] = json_sys_pilot;
  sendMsg(JSON.stringify(json_packet));
}

export const setGetDroneInfoCallback = (callback) => {
  getDroneInfoCallback = callback;
}

export const getDroneModelName = (uuid) => {
  var drone_info;
  for (drone_info of currDroneInfo) {
    if (drone_info[DRONE_UUID] == uuid) {
      return drone_info[CMD_GET_DRONE_INFO_MODEL_NAME_KEY] || "No model name"
    }
  }
  return ""
}

export const sendGetDroneStatus = (uuid) => {
  var json_sys_pilot = {};
  var json_packet = {};
  json_sys_pilot[CMD_KEY] = CMD_GET_DRONE_STATUS_KEY;
  json_sys_pilot[CMD_TYPE] = "req";
  json_sys_pilot[AUTH_KEY_USER] = getUsername();
  json_sys_pilot[AUTH_KEY_SKEY] = gSKey;
  json_sys_pilot[DRONE_UUID] = uuid;
  json_sys_pilot[ACTION_KEY] = ACTION_VAL_SYS_BROWSER;
  json_packet[DATA_KEY] = json_sys_pilot;
  sendMsg(JSON.stringify(json_packet));
}

export const setGetDroneStatusCallback = (callback) => {
  getDroneStatusCallback = callback;
}

export const startGetDroneStatusPolling = (uuid) => {
  currPollId = setInterval(
    () => {
      sendGetDroneStatus(uuid);
    },
    1000
  );
}

export const stopGetDroneStatusPolling = () => {
  if (currPollId) {
    clearInterval(currPollId);
  }
  currPollId = null;
}

export const isGetDroneStatusPolling = () => {
  return Boolean(currPollId);
}

var sendMsg = (msg) => {
  waitForConnectionReady(
    wsConn,
    () => {
      wsConn.send(msg)
    }
  );
}

var waitForConnectionReady = (ws, callback) => {
  setTimeout(
    () => {
      if (ws.readyState === 1) {
        if (callback != null) {
          callback();
        }
      } else {
        waitForConnectionReady(ws, callback);
      }
    },
    5
  );
}

export const closeDroneWebSocket = () => {
  stopGetDroneStatusPolling();
  if (wsConn != null) {
    wsConn.close();
  }
  wsConn = null;
  gSKey = null;
  getDroneInfoCallback = null;
  getDroneStatusCallback = null;
  currDroneInfo = [];
  currDroneStatus = null;
}

export const sendGetCameraProperty = (uuid) => {
  var json_sys_pilot = {};
  var json_packet = {};

  json_sys_pilot[CMD_KEY] = CMD_GET_CAMERA_PROPERTY;
  json_sys_pilot[CMD_TYPE] = "req";
  json_sys_pilot[AUTH_KEY_USER] = getUsername();
  json_sys_pilot[DRONE_UUID] = uuid;
  // json_sys_pilot[AUTH_KEY_SKEY] = gSKey;
  json_packet[ACTION_KEY] = ACTION_VAL_SYS_BROWSER;
  json_packet[DATA_KEY] = json_sys_pilot;
  sendMsg(JSON.stringify(json_packet));
}

export const setGetCameraPropertyCallback = (callback) => {
  getCameraPropertyCallback = callback;
}

export const sendCameraControl = (uuid, params) => {
  var json_data = {};
  var json_packet = {};
  var json_camera_params = params;

  json_data[CMD_KEY] = CMD_BROWSER_CAMERA_CONTROL;
  json_data[CMD_TYPE] = "req";
  json_data[AUTH_KEY_USER] = getUsername();
  json_data[DRONE_UUID] = uuid;
  json_data[CAMERA_ACTION_KEY] = CAMERA_ACTION_CAMERA_SET_PARAMS;
  json_data[CAMERA_PARAMS_KEY] = json_camera_params;

  json_packet[ACTION_KEY] = ACTION_VAL_SYS_BROWSER;
  json_packet[DATA_KEY] = json_data;

  sendMsg(JSON.stringify(json_packet));
}

export const sendCameraControlOneShotPicture = (uuid) => {
  var json_data = {};
  var json_packet = {};

  json_data[CMD_KEY] = CMD_BROWSER_CAMERA_CONTROL;
  json_data[CMD_TYPE] = "req";
  json_data[AUTH_KEY_USER] = getUsername();
  json_data[DRONE_UUID] = uuid;
  json_data[CAMERA_ACTION_KEY] = CAMERA_ACTION_ONE_SHOT;

  json_packet[ACTION_KEY] = ACTION_VAL_SYS_BROWSER;
  json_packet[DATA_KEY] = json_data;

  sendMsg(JSON.stringify(json_packet));
}

export const sendBurstShot = (uuid, action) => {
  var json_data = {};
  var json_packet = {};

  json_data[CMD_KEY] = CMD_BROWSER_CAMERA_CONTROL;
  json_data[CMD_TYPE] = "req";
  json_data[AUTH_KEY_USER] = getUsername();
  json_data[DRONE_UUID] = uuid;
  json_data[CAMERA_ACTION_KEY] = CAMERA_ACTION_BURST_SHOT;
  json_data["flag"] = action ? "true" : "false";

  json_packet[ACTION_KEY] = ACTION_VAL_SYS_BROWSER;
  json_packet[DATA_KEY] = json_data;

  sendMsg(JSON.stringify(json_packet));
}

