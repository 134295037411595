import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import DroneView from '@/views/DroneView.vue'

import { isAuthorizedGuard } from '@/cognito/auth'
import { getDroneModelName } from '@/websocket/drone-websocket-api'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    children: [
      {
        path: 'drone/:id',
        component: DroneView,
        beforeEnter: (to, from, next) => {
          if (getDroneModelName(to.params.id)) {
            next();
          } else {
            next({ path: '/' });
          }
        },
      }
    ],
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/LoginView.vue')
  }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach(isAuthorizedGuard)

export default router
