import { AWS } from '@/cognito/auth'

export async function listAllObjectsFromS3Bucket(bucket, prefix, extensions = []) {
  const s3 = new AWS.S3({
    apiVersion: '2006-03-01',
  });

  let isTruncated = true;
  let marker = null;
  const elements = [];
  while(isTruncated) {
    let params = { Bucket: bucket };
    if (prefix) params.Prefix = prefix;
    if (marker) params.Marker = marker;
    try {
      const response = await s3.listObjects(params).promise();
      response.Contents.forEach(item => {
        if (extensions.length > 0 && !extensions.includes(item.Key.split('.').pop())) {
          return;
        }
        elements.push(item);
      });
      isTruncated = response.IsTruncated;
      if (isTruncated) {
        marker = response.Contents.slice(-1)[0].Key;
      }
  } catch(error) {
      throw error;
    }
  }
  return elements;
}
