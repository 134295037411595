<template>
  <div class="drone">
    <div>
      <div class="row">
        <div class="col">
          <h2>&nbsp;{{ drone_model_name }}: Health=<span class="text-primary" v-if="websocket_status">OK</span><span class="text-danger" v-else>NG</span>&nbsp;<button v-if="!websocket_status" v-on:click="reconnectDroneWebSocket" type="button" class="btn btn-danger btn-sm">Reconnect</button></h2>
        </div>
        <div class="col">
        </div>
      </div>
      <b-tabs content-class="mt-3">
        <b-tab title="Dashboard" active><p>
          <div class="row">
            <div class="col px-0 mx-3 mb-5">
              <div class="pb-1 text-right">
                <div class="row">
                  <div class="col text-left"></div>
                  <div class="col">
                    <div v-if="curr_streaming.signaling_client == null || curr_streaming.peer_connection == null" class="d-inline">
                      <button v-on:click="startStreaming" type="button" class="btn btn-primary btn-sm">Start</button>
                    </div>
                    <div v-else-if="curr_streaming.remote_stream == null" class="d-inline">
                      <span>Waiting for the streaming...</span>
                    </div>
                    <div v-else class="d-inline">
                      <button v-on:click="stopStreaming" type="button" class="btn btn-danger btn-sm">Stop</button>
                    </div>
                  </div>
                </div>
              </div>
              <video id="streaming" class="border" autoplay controls ></video>
            </div>
            <div class="col">
              <div class="row">
                <div class="col text-right h2">
                  <span v-if="rf_type_badge" class="badge badge-pill badge-info">{{ rf_type_badge }}</span>&nbsp;
                  <span v-if="location[1] && location[2]" class="badge badge-pill badge-info">{{ rtk_or_gps }}</span>&nbsp;
                  <b-button-toolbar class="d-inline">
                    <b-button-group>
                      <b-button v-on:click="updateMapCenter" title="Update map center">
                        <b-icon icon="geo" aria-hidden="true"></b-icon>
                      </b-button>
                      <b-button v-on:click="resetPolyline" title="Reset polyline">
                        <b-icon icon="eraser" aria-hidden="true"></b-icon>
                      </b-button>
                    </b-button-group>
                  </b-button-toolbar>
                </div>
              </div>
              <div id="map"></div>
              <table class="table">
                <tbody v-if="drone_status">
                  <tr>
                    <th>Polling</th>
                    <td v-if="is_polling"><button v-on:click="toggleGetDroneStatusPolling" type="button" class="btn btn-danger btn-sm">Stop</button></td>
                    <td v-else><button v-on:click="toggleGetDroneStatusPolling" type="button" class="btn btn-primary btn-sm">Start</button></td>
                    <th>Accuracy</th>
                    <td v-if="location[4]">
                      <span v-if="rtk_or_gps==='RTK'">
                        <span v-if="location[7]==1" class="badge badge-pill badge-primary">Float</span>
                        <span v-else-if="location[7]==2" class="badge badge-pill badge-info">Fix</span>
                      </span>
                      {{ location[4].toFixed(3) + " [m]" }}
                    </td>
                    <td v-else>Unknown</td>
                  </tr>
                  <tr>
                    <th>Timestamp</th>
                    <td>{{ drone_status[CMD_GET_DRONE_STATUS_KEY_TIMESTAMP] ? new Date(drone_status[CMD_GET_DRONE_STATUS_KEY_TIMESTAMP]).toLocaleString() : "Unknown" }}</td>
                    <th>Direction</th>
                    <td v-if="drone_status[CMD_GET_DRONE_STATUS_KEY_DIRECTION]"><b-icon icon="arrow-up" v-bind:rotate="drone_status[CMD_GET_DRONE_STATUS_KEY_DIRECTION]"></b-icon></td>
                    <td v-else>Unknown</td>
                  </tr>
                  <tr>
                    <th>Flight Time</th>
                    <td>{{ drone_status[CMD_GET_DRONE_STATUS_KEY_FLIGHT_TIME] || "Unknown" }}</td>
                    <th>Temperature</th>
                    <td>{{ drone_status[CMD_GET_DRONE_STATUS_KEY_TEMPERATURE] ? drone_status[CMD_GET_DRONE_STATUS_KEY_TEMPERATURE] + "C" : "Unknown" }}</td>
                  </tr>
                  <tr>
                    <th>Remaining Battery</th>
                    <td>{{ drone_status[CMD_GET_DRONE_STATUS_KEY_SYS_BATTERY] ? (drone_status[CMD_GET_DRONE_STATUS_KEY_SYS_BATTERY] * 100).toFixed(0) + "%" : "Unknown" }}</td>
                    <th>RF Type (Strength)</th>
                    <td v-if="drone_status[CMD_GET_DRONE_STATUS_KEY_COMM_LINK]">
                      {{ drone_status[CMD_GET_DRONE_STATUS_KEY_COMM_LINK] }} {{ rf_type_badge == "5G NSA" ? "5G" : "" }}
                      ({{ drone_status[CMD_GET_DRONE_STATUS_KEY_COMM_LINK] == "WiFi" ? drone_status[CMD_GET_DRONE_STATUS_KEY_RF_STRENGTH] : rsrp + "dBm"}})
                    <td v-else>
                      Unknown
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <hr/>
          <h4>
            <select name="mission" :value="selected_mission" @change="changeMission($event.target.value)" v-if="!is_polling">
              <option disabled :value="DEFAULT_MISSION">-- select a mission --</option>
              <option v-for="m in missions" v-bind:value="m">{{ m }}</option>
            </select>
            <span v-if="is_polling">Mission: {{ selected_mission == DEFAULT_MISSION ? "None" : selected_mission }}</span>
            <span v-if="is_polling && upload_progress">, Uploaded: {{upload_progress}}</span>
          </h4>

          <div class="row" v-for="i in Math.ceil(getUrls.length / 5)">
            <div v-for="j in getUrls.slice((i - 1) * 5, i * 5).length" class="col my-2 text-center">
              <div v-if="!getUrls[j - 1 + (i - 1) * 5]">
                <b-icon icon="arrow-clockwise" animation="spin-pulse" font-scale="4"></b-icon>
              </div>
              <div v-else>
                <img v-bind:src="getUrls[j - 1 + (i - 1) * 5]" class="img-thumbnail mb-2 shadow" data-toggle="modal" v-bind:data-target="'#image-modal-url-' + (j - 1 + (i - 1) * 5)">
                <span>{{ getKeys[j - 1 + (i - 1) * 5].filename }} ({{ getKeys[j - 1 + (i - 1) * 5].filesize }}MB)</span>
              </div>

              <div class="modal fade" v-bind:id="'image-modal-url-' + (j - 1 + (i - 1) * 5)" tabindex="-1">
                <div class="modal-dialog modal-dialog-centered modal-lg">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title">{{ getKeys[j - 1 + (i - 1) * 5].filename }} ({{ getKeys[j - 1 + (i - 1) * 5].filesize }}MB, {{ getKeys[j - 1 + (i - 1) * 5].lastModified }})</h5>
                      <button type="button" class="close" data-dismiss="modal">
                        <span>&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      <img v-bind:src="getUrls[j - 1 + (i - 1) * 5]" class="img-fluid" style="max-width: 100%; height: auto; max-height: 70vh;">
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div v-for="j in (5 - getUrls.slice((i - 1) * 5, i * 5).length)" class="col">
            </div>
          </div>
          <div v-if="urls.length > 0">
            <vuejs-paginate
              :page-count="getPaginateCountForUrls"
              :page-range="3"
              :margin-pages="2"
              :prev-text="'<'"
              :next-text="'>'"
              :click-handler="paginateClickCallbackForUrls"
              :first-last-button="true"
              :first-button-text="'<<'"
              :last-button-text="'>>'"
              :container-class="'pagination justify-content-center'"
              :page-class="'page-item'"
              :page-link-class="'page-link'"
              :prev-class="'page-item'"
              :prev-link-class="'page-link'"
              :next-class="'page-item'"
              :next-link-class="'page-link'"
            ></vuejs-paginate>
          </div>
        </p></b-tab>
        <b-tab title="Camera Settings"><p>
          <CameraSettingsView/>
        </p></b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import CameraSettingsView from '@/views/CameraSettingsView'
import { AWS } from '@/cognito/auth'
import { connectDroneWebSocket, sendLogin, getDroneModelName, setGetDroneStatusCallback, startGetDroneStatusPolling, stopGetDroneStatusPolling, isGetDroneStatusPolling, setOnOpenCallback, setOnCloseCallback } from '@/websocket/drone-websocket-api'

import { CMD_GET_DRONE_STATUS_KEY_LOCATION,
         CMD_GET_DRONE_STATUS_KEY_RTK_LOCATION,
         CMD_GET_DRONE_STATUS_KEY_DIRECTION,
         CMD_GET_DRONE_STATUS_KEY_FLIGHT_TIME,
         CMD_GET_DRONE_STATUS_KEY_TIMESTAMP,
         CMD_GET_DRONE_STATUS_KEY_TEMPERATURE,
         CMD_GET_DRONE_STATUS_KEY_SYS_BATTERY,
         CMD_GET_DRONE_STATUS_KEY_COMM_LINK,
         CMD_GET_DRONE_STATUS_KEY_RF_STRENGTH,
         CMD_GET_DRONE_STATUS_KEY_LATEST_FILE_UPTIME,
         CMD_GET_DRONE_STATUS_KEY_DRN_STATUS,
         CMD_GET_DRONE_STATUS_KEY_MISSION } from '@/websocket/drone-websocket-api'
import { startKinesisStreaming, stopKinesisStreaming } from '@/streaming/kinesis'
import { initializeMap, addImageMarker, resetImageMarkers, addPolyline, updateMapCenter, resetPolyline } from "@/utils/map"
import { listAllObjectsFromS3Bucket } from "@/utils/s3"
import exifr from "exifr"
const AsyncLock = require('async-lock');

var curr_file_uptime = new Date(0);
var fetch_file_retries = 0;
const lock = new AsyncLock({maxPending: 0});
const lockKeyForTodayImages = "updateUrls";
const DEFAULT_MISSION = "162c497d-0520-ed4a-d6c2-cdde5bd89b8b"
var prev_mission = DEFAULT_MISSION;

const updateUrls = async(urls, missions, drone_id, selected_mission, filter, marker_callback, prefetch=process.env.VUE_APP_IMAGES_PER_PAGE) => {
  let s3 = new AWS.S3({
    apiVersion: '2006-03-01',
    params: { Bucket: process.env.VUE_APP_S3_BUCKET }
  });
  const prefix = "cognito/" + AWS.config.credentials.identityId + "/" + drone_id + "/";
  const prefixForMission = "cognito/" + AWS.config.credentials.identityId + "/" + drone_id + "/" + selected_mission + "/";

  let image;
  let images = await listAllObjectsFromS3Bucket(process.env.VUE_APP_S3_BUCKET, prefix, ["jpeg", "jpg", "JPEG", "JPG"]);

  let is_mission_changed = false;
  let tmp_urls = [];
  if (prev_mission == selected_mission) {
    tmp_urls = urls.slice(0, urls.length)
  } else {
    prev_mission = selected_mission;
    is_mission_changed = true;
  }

  let tmp_missions = missions.slice(0, missions.length);
  let promises = [];
  for (image of images) {
    if (filter && !filter(image)) {
      continue;
    }

    const imageKey = image.Key;
    if (imageKey === prefix) {
      continue;
    }

    if (!tmp_missions.includes(imageKey)) {
      const re_mission = new RegExp(prefix + "([^/]+)/", "g");
      let m = null;
      let mission = null;

      if ((m = re_mission.exec(imageKey)) != null) {
        mission = m[1];
      }

      if (mission != null && !tmp_missions.includes(mission)) {
        tmp_missions.push(mission);
      }
    }

    if (imageKey.indexOf(prefixForMission) !== 0) {
      continue;
    }

    if (!tmp_urls.map((elem) => {return elem.key}).includes(imageKey)) {
      tmp_urls.push({key: imageKey, size: image["ContentLength"], lastModified: image["LastModified"], url: null});
    }
  }
  tmp_urls.sort((first, second) => {
    let first_key = first.lastModified
    let second_key = second.lastModified
    return (second_key > first_key) - (second_key < first_key)
  })
  tmp_urls.slice(0, prefetch).forEach((obj) => {
    if (!obj.url) {
      promises.push(new Promise((resolve, reject) => {
        s3.getObject({ Key: obj.key }, (err, file) => {
          if (err) {
            reject(err);
          } else {
            exifr.gps(file.Body).then((latlon) => {
              if (latlon) {
                marker_callback({lat: latlon["latitude"], lng: latlon["longitude"]}, obj.key.split("/").slice(-1)[0]);
              }
            });
            const blob = new Blob([file.Body], {type: file.ContentType});
            const url = URL.createObjectURL(blob);
            let idx = tmp_urls.findIndex((elem) => {
              return elem.key == obj.key;
            });
            tmp_urls[idx].size = file.ContentLength;
            tmp_urls[idx].url = url;
            resolve();
          }
        });
      }));
    }
  });
  await Promise.all(promises).then(() => {
    if (is_mission_changed) {
      urls.forEach((elem) => {
        URL.revokeObjectURL(elem.url);
        elem.url = null;
      });
    } else {
      tmp_urls.slice(prefetch).forEach((elem) => {
        URL.revokeObjectURL(elem.url);
        elem.url = null;
      });
    }
    urls.splice(0, urls.length);
    urls.push(...tmp_urls);
    missions.splice(0, missions.length);
    missions.push(...tmp_missions);
  });
}

const updateView = (th) => {
  setOnOpenCallback(() => {
    th.websocket_status = true;
  });
  setOnCloseCallback(() => {
    th.websocket_status = false;
  });
  th.drone_model_name = getDroneModelName(th.$route.params.id);
  th.upload_progress = "";
  th.drone_status = {};
  th.location = [];
  th.rf_type_badge = "";
  stopGetDroneStatusPolling();
  th.is_polling = false;
  initializeMap();
  th.urls.forEach((elem) => {
    URL.revokeObjectURL(elem.url);
  });
  th.urls.splice(0, th.urls.length);
  th.missions.splice(0, th.missions.length);
  th.selected_mission = DEFAULT_MISSION;
  lock.acquire(lockKeyForTodayImages, async (done) => {
    await updateUrls(th.urls, th.missions, th.$route.params.id, th.selected_mission, null, addImageMarker);
    done();
  }).catch((err) => {
    // Nothing to do
  });
  th.curr_page_for_urls = 1,

  curr_file_uptime = new Date(0);
  fetch_file_retries = 0;
  stopKinesisStreaming(th.curr_streaming);
}

export default {
  name: 'DroneView',
  components: {
    CameraSettingsView,
  },
  data () {
    return {
      websocket_status: true,
      urls: [],
      missions: [],
      selected_mission: DEFAULT_MISSION,
      drone_status: {},
      location: [],
      upload_progress: "",
      rtk_or_gps: "",
      rf_type_badge: "",
      rsrp: "",
      drone_model_name: "",
      curr_streaming: {
        signaling_client: null,
        peer_connection: null,
        remote_stream: null,
        remote_view: null,
      },
      is_polling: false,
      curr_page_for_urls: 1,
      per_page_for_urls: process.env.VUE_APP_IMAGES_PER_PAGE,
      CMD_GET_DRONE_STATUS_KEY_LOCATION: CMD_GET_DRONE_STATUS_KEY_LOCATION,
      CMD_GET_DRONE_STATUS_KEY_RTK_LOCATION: CMD_GET_DRONE_STATUS_KEY_RTK_LOCATION,
      CMD_GET_DRONE_STATUS_KEY_TIMESTAMP: CMD_GET_DRONE_STATUS_KEY_TIMESTAMP,
      CMD_GET_DRONE_STATUS_KEY_DIRECTION: CMD_GET_DRONE_STATUS_KEY_DIRECTION,
      CMD_GET_DRONE_STATUS_KEY_FLIGHT_TIME: CMD_GET_DRONE_STATUS_KEY_FLIGHT_TIME,
      CMD_GET_DRONE_STATUS_KEY_TEMPERATURE: CMD_GET_DRONE_STATUS_KEY_TEMPERATURE,
      CMD_GET_DRONE_STATUS_KEY_SYS_BATTERY: CMD_GET_DRONE_STATUS_KEY_SYS_BATTERY,
      CMD_GET_DRONE_STATUS_KEY_COMM_LINK: CMD_GET_DRONE_STATUS_KEY_COMM_LINK,
      CMD_GET_DRONE_STATUS_KEY_RF_STRENGTH: CMD_GET_DRONE_STATUS_KEY_RF_STRENGTH,
      CMD_GET_DRONE_STATUS_KEY_DRN_STATUS: CMD_GET_DRONE_STATUS_KEY_DRN_STATUS,
      DEFAULT_MISSION: DEFAULT_MISSION,
    }
  },
  created () {
    updateView(this);
  },
  watch: {
    $route(to, from) {
      updateView(this);
    }
  },
  methods: {
    paginateClickCallbackForUrls(page_num) {
      let start = (Number(page_num) - 1) * this.per_page_for_urls;
      let end = Number(page_num) * this.per_page_for_urls;

      let s3 = new AWS.S3({
        apiVersion: '2006-03-01',
        params: { Bucket: process.env.VUE_APP_S3_BUCKET }
      });

      let promises = [];

      this.urls.forEach((elem, idx) => {
        URL.revokeObjectURL(elem.url);
        elem.url = null;
      });

      resetImageMarkers();
      this.urls.slice(start, end).forEach((elem, idx) => {
        if (elem.url != null) {
          return;
        }
        promises.push(new Promise((resolve, reject) => {
          s3.getObject({ Key: elem.key }, (err, file) => {
            if (err) {
              reject(err);
            } else {
              exifr.gps(file.Body).then((latlon) => {
                if (latlon) {
                  addImageMarker({lat: latlon["latitude"], lng: latlon["longitude"]}, elem.key.split("/").slice(-1)[0]);
                }
              });
              const blob = new Blob([file.Body], {type: file.ContentType});
              const url = URL.createObjectURL(blob);
              elem.size = file.ContentLength;
              elem.lastModified = file.LastModified;
              elem.url = url;
              resolve();
            }
          });
        }));
      });
      Promise.all(promises).then(() => {
        this.curr_page_for_urls = Number(page_num);
      });
    },
    startStreaming() {
      this.curr_streaming["remote_view"] = document.getElementById("streaming");
      startKinesisStreaming(this.curr_streaming, this.$route.params.id);
    },
    stopStreaming() {
      stopKinesisStreaming(this.curr_streaming);
    },
    toggleGetDroneStatusPolling() {
      if (isGetDroneStatusPolling()) {
        stopGetDroneStatusPolling();
        this.is_polling = false;
      } else {
        setGetDroneStatusCallback((drone_status) => {
          if (!Object.keys(drone_status).length) {
            return;
          }

          let diffMilliSec = new Date() - new Date(drone_status[CMD_GET_DRONE_STATUS_KEY_TIMESTAMP]);
          let diffMins = parseInt(diffMilliSec / 1000 / 60);
          if (diffMins > process.env.VUE_APP_DRONE_STATUS_TIMEOUT) {
            return;
          }

          this.drone_status = drone_status;

          if (CMD_GET_DRONE_STATUS_KEY_MISSION in drone_status) {
            this.selected_mission = drone_status[CMD_GET_DRONE_STATUS_KEY_MISSION];
          }

          if (CMD_GET_DRONE_STATUS_KEY_LATEST_FILE_UPTIME in drone_status) {
            let latest_file_info = drone_status[CMD_GET_DRONE_STATUS_KEY_LATEST_FILE_UPTIME].toString(10).split(",")
            let latest_file_uptime = new Date(Number(latest_file_info[0]));
            if (latest_file_info[1] && latest_file_info[2]) {
              this.upload_progress = `[${latest_file_info[1]}/${latest_file_info[2]}]`;
            } else {
              this.upload_progress = "";
            }
            if (curr_file_uptime < latest_file_uptime || fetch_file_retries > 0) {
              lock.acquire(lockKeyForTodayImages, async (done) => {
                if (curr_file_uptime < latest_file_uptime) {
                  curr_file_uptime = latest_file_uptime;
                  fetch_file_retries = 6;
                } else {
                  fetch_file_retries--;
                }
                await updateUrls(this.urls, this.missions, this.$route.params.id, this.selected_mission, null, addImageMarker);
                done();
              }).catch((err) => {
                // Nothing to do
              });
            }
          }

          let location = drone_status[CMD_GET_DRONE_STATUS_KEY_LOCATION].split(":").map(Number);
          this.location = location;
          this.rtk_or_gps = "GPS";
          if (drone_status[CMD_GET_DRONE_STATUS_KEY_RTK_LOCATION]) {
            let rtk_location = null;
            rtk_location = drone_status[CMD_GET_DRONE_STATUS_KEY_RTK_LOCATION].split(":").map(Number);
            if (location[4] && rtk_location[4]) {
              if (rtk_location[4] < location[4]) {
                this.location = rtk_location;
                this.rtk_or_gps = "RTK";
              }
            }
          }
          addPolyline({ lat: this.location[1], lng: this.location[2] });

          if (CMD_GET_DRONE_STATUS_KEY_DRN_STATUS in drone_status) {
            const re_rsrp = new RegExp('rsrp=(-?[0-9]+)', 'g');
            const re_ssrsrp = new RegExp('ssRsrp = (-?[0-9]+)', 'g');
            let m = null;
            let rsrp = null;

            if ((m = re_ssrsrp.exec(drone_status[CMD_GET_DRONE_STATUS_KEY_DRN_STATUS])) != null) {
              this.rf_type_badge = "5G NSA"
              rsrp = m[1];
            } else if ((m = re_rsrp.exec(drone_status[CMD_GET_DRONE_STATUS_KEY_DRN_STATUS])) != null) {
              this.rf_type_badge = "LTE"
              rsrp = m[1];
            }

            if (rsrp != null) {
              this.rsrp = rsrp;
            }
          }

          if (CMD_GET_DRONE_STATUS_KEY_COMM_LINK in drone_status) {
            if (drone_status[CMD_GET_DRONE_STATUS_KEY_COMM_LINK] == "WiFi") {
              this.rf_type_badge = "WiFi"
            }
          }
        });
        startGetDroneStatusPolling(this.$route.params.id);
        this.is_polling = true;
      }
    },
    updateMapCenter () {
      updateMapCenter();
    },
    resetPolyline () {
      resetPolyline();
    },
    reconnectDroneWebSocket () {
      connectDroneWebSocket();
      sendLogin();
    },
    changeMission (value) {
      this.selected_mission = value;

      lock.acquire(lockKeyForTodayImages, async (done) => {
        await updateUrls(this.urls, this.missions, this.$route.params.id, this.selected_mission, null, addImageMarker);
        done();
      }).catch((err) => {
        // Nothing to do
      });
    },
  },
  computed: {
    getUrls: function () {
      let start = (this.curr_page_for_urls - 1) * this.per_page_for_urls;
      let end = this.curr_page_for_urls * this.per_page_for_urls;
      return this.urls.map((elem) => {return elem.url}).slice(start, end);
    },
    getKeys: function () {
      let start = (this.curr_page_for_urls - 1) * this.per_page_for_urls;
      let end = this.curr_page_for_urls * this.per_page_for_urls;
      return this.urls.map((elem) => {
        return {
          filename: elem.key.split("/").slice(-1)[0],
          filesize: (elem.size/1000/1000).toFixed(1),
          lastModified: elem.lastModified ? elem.lastModified.toLocaleString() : "",
        }
      }).slice(start, end);
    },
    getPaginateCountForUrls: function () {
      return Math.ceil(this.urls.length / this.per_page_for_urls);
    },
  }
}
</script>

<style lang="scss" scoped>
#map {
  width: 100%;
  height: 25vh;
}

#streaming {
  width: 100%;
  height: 40vh;
}
</style>
