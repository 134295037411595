const AmazonCognitoIdentity = require('amazon-cognito-identity-js');
import { CognitoUserPool, CognitoUser } from 'amazon-cognito-identity-js';

export const AWS = require('aws-sdk');
require('amazon-cognito-js');

const REGION = process.env.VUE_APP_COGNITO_REGION;
const POOL_DATA = {
  UserPoolId: process.env.VUE_APP_USERPOOL_ID,
  ClientId: process.env.VUE_APP_CLIENT_ID
};
const IDENTITY_POOL_ID = process.env.VUE_APP_IDENTITY_POOL_ID;
const LOGINS_KEY = 'cognito-idp.' + REGION + '.amazonaws.com/' + POOL_DATA.UserPoolId;
const gCognitoUserPool = new CognitoUserPool(POOL_DATA);

var gUsername = "Unknown";
var gUserAttrs = {};
var gAccessToken = null;
var gIdToken = null;

export const getUsername = () => {
  return gUsername;
}

export const getEmail = () => {
  return gUserAttrs["email"];
}

export const getIdToken = () => {
  return gIdToken;
}

export const getAccessToken = () => {
  return gAccessToken;
}

export const isAuthorizedGuard = (to, from, next) => {
  if (to.matched.some(page => page.meta.requiresAuth)) {
    isAuthenticated().then(() => {
      next()
    }).catch(() => {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    });
  } else {
    next()
  }
}

export const isAuthenticated = () => {
  return new Promise((resolve, reject) => {
    const cognitoUser = gCognitoUserPool.getCurrentUser();

    if (!cognitoUser) {
      reject('Could not retrieve current user');
    }

    cognitoUser.getSession((err, session) => {
      if (err) {
        reject('Error retrieving user session: ', err);
      }

      if (session.isValid()) {
        gUsername = cognitoUser.getUsername();
        gIdToken = session.getIdToken();
        gAccessToken = session.getAccessToken();
        cognitoUser.getUserAttributes((err, attrs) => {
          if (err) {
            return;
          }
          attrs.forEach((elem, idx) => {
            gUserAttrs[elem.getName()] = elem.getValue();
          });
        });
        resolve();
      } else {
        reject('Session is not valid');
      }
    });
  }).then(() => {
    return new Promise((resolve, reject) => {
      const cognitoIdentityParams = {
        IdentityPoolId: IDENTITY_POOL_ID,
        Logins: {
          [LOGINS_KEY]: gIdToken.getJwtToken()
        }
      };

      AWS.config.region = REGION;
      AWS.config.credentials = new AWS.CognitoIdentityCredentials(cognitoIdentityParams);

      AWS.config.credentials.refresh((err) => {
        if (err){
          reject(err);
        }
        resolve();
      });
    });
  });
}

export const loginUser = (username, password) => {
  const authData = {
    Username: username,
    Password: password
  };
  const authDetails = new AmazonCognitoIdentity.AuthenticationDetails(authData);
  const userData = {
    Username: username,
    Pool: gCognitoUserPool
  };

  const cognitoUser = new CognitoUser(userData);

  return new Promise((resolve, reject) => {
    cognitoUser.authenticateUser(authDetails, {
      onSuccess: (result) => {
        resolve(result);
      },
      onFailure: (err) => {
        reject(err);
      },
    });
  });
}

export const logoutUser = () => {
  return new Promise((resolve) => {
    var cognitoUser = gCognitoUserPool.getCurrentUser();
    if (cognitoUser != null) {
      cognitoUser.signOut();
    }
    resolve();
  });
}
