<template>
  <div class="cameraSettings">
    <div class="form-group">
      <button v-on:click="refreshSettings" type="button" class="btn btn-primary mr-1">Refresh settings</button>
      <button v-on:click="takePhoto" type="button" class="btn btn-primary mr-1">Take a photo</button>
      <button v-on:click="startContinuousShooting" type="button" class="btn btn-primary mr-1">Start continuous shooting</button>
      <button v-on:click="stopContinuousShooting" type="button" class="btn btn-danger mr-1">Stop continuous shooting</button>
    </div>

    <div class="form-group" v-for="(prop, key) in getProps">
      <h3>{{ key }}</h3>
      <select v-if="prop.writable" v-model="prop.current" class="form-control">
        <option v-for="(option_str, option_value) in prop.options" v-bind:value="option_value">{{ option_str }}</option>
      </select>
      <select v-else class="form-control" disabled>
        <option v-bind:value="prop.current">{{ prop.options[prop.current] }}</option>
      </select>
    </div>

    <div class="form-group">
      <button v-on:click="submitCameraControl" type="button" class="btn btn-primary mr-1">Submit</button>
    </div>
  </div>
</template>
<script>
import { sendGetCameraProperty, setGetCameraPropertyCallback, sendCameraControl, sendCameraControlOneShotPicture, sendBurstShot } from '@/websocket/drone-websocket-api'

// This function destructs props arg.
const initializeCameraPropertyOptions = (props) => {
  delete props.model_name;
  for (let key in props) {
    let prop = props[key];
    const re_possible_str = new RegExp("[\\[\\]]", "g");
    prop.possible = JSON.parse(prop.possible);
    prop.possible_str = prop.possible_str.replace(re_possible_str, "");
    prop.possible_str = prop.possible_str.split(", ");

    // Add options attr for select tag
    prop.options = {};
    if (prop.possible.length == prop.possible_str.length) {
      let option_len = prop.possible.length
      for (let i=0; i<option_len; i++) {
        prop.options[prop.possible[i]] = prop.possible_str[i];
      }
    }

    // Add current value snapshot to detect change
    prop.current_snapshot = prop.current;
  }
}

export default {
  data () {
    return {
      props: {},
    }
  },
  created () {
    setGetCameraPropertyCallback((props) => {
      initializeCameraPropertyOptions(props.camera_id0);
      this.props = props.camera_id0;
    });
  },
  watch: {
    $route(to, from) {
      setGetCameraPropertyCallback((props) => {
        initializeCameraPropertyOptions(props.camera_id0);
        this.props = props.camera_id0;
      });
    }
  },
  methods: {
    refreshSettings() {
      sendGetCameraProperty(this.$route.params.id);
    },
    takePhoto() {
      sendCameraControlOneShotPicture(this.$route.params.id);
    },
    submitCameraControl() {
      let params = {};
      for (let key in this.props) {
        let prop = this.props[key];
        if (prop.writable && prop.current_snapshot != prop.current) {
          params[key] = prop.current;
        }
      }
      if (Object.keys(params).length > 0) {
        sendCameraControl(this.$route.params.id, params);
        alert("Submit success");
      } else {
        alert("No params changed");
      }
    },
    startContinuousShooting() {
      sendBurstShot(this.$route.params.id, true);
    },
    stopContinuousShooting() {
      sendBurstShot(this.$route.params.id, false);
    }
  },
  computed: {
    getProps: function () {
      return this.props;
    },
  }
}
</script>

<style lang="scss" scoped>
</style>
